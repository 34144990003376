@import url(https://fonts.googleapis.com/css?family=Handlee);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {background: #333;display:flex;align-items:center;min-height:100vh;justify-content:center}
.container{
	align-items:center;
	display: flex;
	flex-direction: row-reverse;
}
.note {
	color:#333;
	position:relative;
	height:auto;
	margin:0 auto;
	padding:2vmin 2vmin 1vmin 2vmin;
	box-shadow:0 10px 10px 2px rgba(0,0,0,0.3);
	width:100%;
	z-index:1;
	border:none;
	border-bottom-right-radius: 50% 15%	;
	background: linear-gradient(165deg, transparent 40%, rgba(0,0,0,0.15) 80%, rgba(255,255,255,0.15) 90%,rgba(255,255,255,0.15) 100%);
  	background-color:#ffffa5; /* Place after background to set paper color */
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-image: linear-gradient(to bottom right, rgba(255,255,255,0.75) 30%, rgba(0,0,0,0.25) 60%, transparent 80%);
	border-image-slice: 1;
	width:350px;
	min-height:300px;
	margin-left: 24px;
}
.note p{
	text-align:center;
	color: #4c4e67;
	font-size:16px;
	font-family: cursive, serif;
	font-size:16px;
}
#keyboard {
	margin: 50px auto;
	width: 1000px;
	height: 315px;
	background:	#d5d9dc  url('') repeat-x;
	-moz-border-radius-topleft: 7px 21px;
	-moz-border-radius-topright: 7px 21px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-top-left-radius: 7px 21px;
	border-top-right-radius: 7px 21px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	padding: 50px 0 0 10px;
	box-shadow:  
		inset 0 0 8px #bbb,
		0 1px 0 #aaa,
		0 4px 0 #bbb,
		0 10px 30px #111;}
#keyboard.laptop,#keyboard.laptop ul{width:918px}
ul {list-style-type: none; width: 1000px; margin: 0 auto;}
li {float: left;position: relative;}
li.target .key{
	color:#fff;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-name: clignoter;
	        animation-name: clignoter;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	transition: ease-in-out;
}
li.clicked .key{
	color:#fff;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-name: clignoteRed;
	        animation-name: clignoteRed;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	transition: ease-in-out;
}
@-webkit-keyframes clignoter {
	0%   { 
		background-color:#222;
		box-shadow: 
			inset 0 0 25px #333,
			0 1px 0 #000,
			0 2px 0 #222,
			0 2px 3px #333;
		text-shadow: 0px -1px 0px #000;
	}
	40%   {
		border-top: none;
		background-color: #03a9f4;
		box-shadow: 
			inset 0 0 25px #58c3f5,
			0 1px 0 #0c8cc7,
			0 2px 0 #03a9f4,
			0 2px 3px #58c3f5;
		text-shadow: 0px -1px 0px #0c8cc7;
	}
	100% {
		background-color:#222; 
	}
}
@keyframes clignoter {
	0%   { 
		background-color:#222;
		box-shadow: 
			inset 0 0 25px #333,
			0 1px 0 #000,
			0 2px 0 #222,
			0 2px 3px #333;
		text-shadow: 0px -1px 0px #000;
	}
	40%   {
		border-top: none;
		background-color: #03a9f4;
		box-shadow: 
			inset 0 0 25px #58c3f5,
			0 1px 0 #0c8cc7,
			0 2px 0 #03a9f4,
			0 2px 3px #58c3f5;
		text-shadow: 0px -1px 0px #0c8cc7;
	}
	100% {
		background-color:#222; 
	}
}
@-webkit-keyframes clignoteRed {
	0%   { 
		background-color:#222;
		box-shadow: 
			inset 0 0 25px #333,
			0 1px 0 #000,
			0 2px 0 #222,
			0 2px 3px #333;
		text-shadow: 0px -1px 0px #000;
	}
	40%   {
		border-top: none;
		background-color: #c52929;
		box-shadow: 
			inset 0 0 25px #f83a3a,
			0 1px 0 #e92525,
			0 2px 0 #c52929,
			0 2px 3px #f83a3a;
		text-shadow: 0px -1px 0px #e92525;
	}
	100% {
		background-color:#222; 
	}
}
@keyframes clignoteRed {
	0%   { 
		background-color:#222;
		box-shadow: 
			inset 0 0 25px #333,
			0 1px 0 #000,
			0 2px 0 #222,
			0 2px 3px #333;
		text-shadow: 0px -1px 0px #000;
	}
	40%   {
		border-top: none;
		background-color: #c52929;
		box-shadow: 
			inset 0 0 25px #f83a3a,
			0 1px 0 #e92525,
			0 2px 0 #c52929,
			0 2px 3px #f83a3a;
		text-shadow: 0px -1px 0px #e92525;
	}
	100% {
		background-color:#222; 
	}
}
.key{
	display: block;
	color: #aaa;
	font: bold 9pt arial;
	text-decoration: none;
	text-align: center;
	width: 42px;
	height: 41px;
	margin: 5px;
	background: #222;
	border-radius: 4px;
	border:none;
	border-top: 1px solid #222;
	box-shadow: 
		inset 0 0 25px #333,
		0 1px 0 #000,
		0 2px 0 #222,
		0 2px 3px #333;
	text-shadow: 0px -1px 0px #000;
}
.key.enter1{
	width:70px
}
.key.enter2{
	width:60px;
	position:relative;
	height: 55px;
	border-top: none;
	margin-top: -9px;
}
.key:active, .keydown {
	color: #888;
	background: #222;
	margin: 7px 5px 3px;
	box-shadow: 
		inset 0 0 25px #333,
		0 0 3px #333;
	border-top: 1px solid #000;}
	
.fn span {
	display: block;
	margin: 14px 5px 0 0;
	text-align: right;
	font: bold 6pt arial;
	text-transform: uppercase;}
.esc {
	margin: 6px 15px 0 0;
	font-size: 7.5pt;
}
#numbers li button span {
	display: block;}
	
#numbers li button b {
	margin: 3px 0 3px;
	display: block;}

#numbers li .alt b {display: block;margin: 0 0 3px;}

#numbers li #delete span, #numbers li #num span{
	text-align: right;
	margin: 23px 10px 0 0;
	font-size: 7.5pt;
	text-transform: lowercase;}
	
#qwerty li button span {
	display: block;
	margin: 13px 0 0;
	text-transform: uppercase;}
	
#qwerty li #tab span {
	text-align: left;
	margin: 23px 0 0 10px;
	font-size: 7.5pt;
	text-transform: lowercase;}	

#qwerty li .alt b {display: block; margin: 3px 0 0;}
#qwerty li .alt span {margin: 2px 0 0;}


#asdfg li button span {
	display: block;
	margin: 13px 0 0;
	text-transform: uppercase;}

#asdfg li .alt span {margin: 0; text-transform: lowercase;}
#asdfg li .alt b {display: block; margin: 3px 0 0;}
#asdfg li #caps b {
	display: block;
	background: #999;
	width: 4px;
	height: 4px;
	border-radius: 10px;
	margin: 9px 0 0 10px;
	box-shadow:inset 0 1px 0 #666;}
#asdfg li #caps span {
	text-align: left;
	margin:0;
	font-size: 7.5pt;}

#zxcvb li button span {
	display: block;
	margin: 13px 0 0;
	text-transform: uppercase;}
#zxcvb li .shiftleft span {
	text-align: left;
	margin: 23px 0 0 10px;
	font-size: 7.5pt;
	text-transform: lowercase;}
#zxcvb li .shiftright span {
	text-align: right;
	margin: 23px 10px 0 0;
	font-size: 7.5pt;
	text-transform: lowercase;}
#zxcvb li .alt b {display: block;margin: 4px 0 0;}
#zxcvb li .alt span {margin: 0;}
#bottomrow li #fn span, #bottomrow li #control span, #bottomrow li #optionleft span, #bottomrow li #commandleft span {
	display: block;
	text-align: left;
	margin: 31px 0 0 8px;
	font-size: 7.5pt;
	text-transform: lowercase;
}
#bottomrow li #optionright span, #bottomrow li #commandright span {
	display: block;
	text-align: right;
	margin: 31px 8px 0 0;
	font-size: 7.5pt;
	text-transform: lowercase;
}
#bottomrow ol li #left span, #bottomrow ol li #right span, #bottomrow ol li #up span, #bottomrow ol li #down span {
	display: block;
	margin: 9px 0 0;
}
.fn {height: 25px; width: 39px;}
#delete {width: 98px;}
#tab {width: 70px;}
#caps {width: 80px;}
.shiftright {width: 130px;}
.shiftleft{width:62px}
#control {width: 56px;}
.option {width: 46px;}
.command {width: 67px;}
#spacebar {width: 254px;}
#left img, #up img, #down img, #right img {border: none;}
#down {height: 23px;}
#up, #left, #right {height: 24px;}
#left, #right {margin: 30px 5px 5px;}
#left:active, #right:active {margin: 32px 5px 3px;}
#up {margin: 5px 5px 1px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;}
#up:active {margin: 8px 5px -2px;}
#down {margin: 0 5px 5px; border-top-left-radius: 0px; border-top-right-radius: 0px;}
#down:Active {margin: 3px 5px 4px;}
#main {
	width: 700px;
	padding: 20px 50px;
	margin: 0 auto 50px;
	background: #fff;
	border-radius: 5px;
	-webkit-box-shadow: 0 1px 2px #aaa;
}
small {
	font: italic 8pt/12pt arial;
	color: #aaa;
	padding: 0 130px 0 0;
	display: block;
}
#main button {color: #007fff;border:none}
ul, ol {padding: 0px; margin: 0;list-style-type: none;}
ol{float:left;display:inline-block}
.cf:before, .cf:after {content:""; display:table;}
.cf:after {clear:both;}
.cf {zoom:1;}
#keyboard.laptop .fn{
	width: 37px;
}
#keyboard.laptop #delete{
	width: 78px;
}
#keyboard.laptop #expo{
	width: 30px;
}
#keyboard.laptop #tab{
	width: 66px;
}
#keyboard.laptop #caps{
	width: 75px;
}
#keyboard.laptop .key.enter1{
	width: 85px;
}
#keyboard.laptop .shiftright{
	width: 98px;
}
#keyboard.laptop .cf:first-child li:nth-last-child(-n+3) button {
	width: 42px;
}
#keyboard.laptop #alt, #keyboard.laptop #altgr{
	width: 52px;
}
#keyboard.laptop #bottomrow .key:not(#up,#left,#down,#right){
	height:50px
}
#zero{
	width: 94px;
	text-align: left;
}
#zero span{
	margin-left: 12px;
}
#zxcvb li #enter,#qwerty li #plus{
	height:92px;
	position: absolute;
}
#zxcvb li #enter span{
	font-size: 7.5pt;
	text-transform: none;
}
